<template>
  <div>
    <helloWorld />
    <div class="free-content">
      <div class="free-left">
        <el-collapse v-model="activeName">
          <el-collapse-item title="类别" name="1">
            <el-radio size="mini" v-model="radio1" label="1" border>游戏</el-radio>
            <el-radio size="mini" v-model="radio1" label="2" border>漫画</el-radio>
            <el-radio size="mini" v-model="radio1" label="3" border>测试</el-radio>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="free-right">
        <div class="free-item" v-for="(list,index) in freeList" :key="index" @click="todetail(list)">
          <el-image
              style="width:100%;height: 220px"
              :src="list.assets"
              fit="fill"></el-image>
          <p>{{list.title}}</p>
          <span class="free-author">{{list.author}}</span>
          <div class="look">
            <div class="look-left">
              <img src="@/assets/give.png" alt="">
              <span>63</span>
            </div>
            <div class="def-buybtn">点击查看</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
    // setInterval(this.startPlay, 2000)
  },
  data() {
    return {
      activeName: ['1'],
      radio1: '1',
      radio2: '1',
      radio3: '1',
      radio4: '1',
      freeList: [
        {
          assets: require(`@/assets/free1.jpg`),
          title: 'Ape # 8928',
          author: '亚联融汇科技有限公司'
        },
        {
          assets: require(`@/assets/free2.jpg`),
          title: 'ARTSYCOLLECTOR #9160',
          author: '亚联融汇科技有限公司'
        },
        {
          assets: require(`@/assets/free3.jpg`),
          title: 'Bath series',
          author: '亚联融汇科技有限公司'
        },
        {
          assets: require(`@/assets/free4.jpg`),
          title: 'Bath series2',
          author: '亚联融汇科技有限公司'
        },
        {
          assets: require(`@/assets/free5.jpg`),
          title: 'Bath series3',
          author: '亚联融汇科技有限公司'
        },
        {
          assets: require(`@/assets/free6.jpg`),
          title: 'BAYC #2033',
          author: '亚联融汇科技有限公司'
        },
        {
          assets: require(`@/assets/free7.jpg`),
          title: 'Obsidian HAPE 1-5',
          author: '亚联融汇科技有限公司'
        },
        {
          assets: require(`@/assets/free8.jpg`),
          title: 'BOSSLOGIC #416',
          author: '亚联融汇科技有限公司'
        },
        {
          assets: require(`@/assets/free9.jpg`),
          title: 'BUFFALOSPACE #6735',
          author: '亚联融汇科技有限公司'
        }
      ], //免费图片列表
    }
  },
  methods: {
    todetail(row) {
      this.$router.push('./freeDetail?author=' + row.author + '&&assets=' + row.assets + '&&title=' + row.title)
    }
  }
}
</script>
<style scoped>
.free-content {
  display: flex;
}
.free-left {
  width: 18%;
  padding:30px 20px;
  border-right: 1px solid #f8f8f8;
}
.free-right {
  width: 80%;
}
.free-right {
  padding: 30px 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.free-item {
  margin:  10px;
  border: 1px solid #eee;
}
.free-item img{
  width: 100%;
}
.free-item p {
      font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1d202b;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 4px;
}
.free-author {
  font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9b9b9b;
    padding: 4px;
}
.look {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f8f8f8;
  margin-top: 8px;
}
.look-left {
  display: flex;
  align-items: center;
}
.look-left img {
  width: 25px;
}
.look-left span {
  color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}
.def-buybtn {
  width: 90px;
    height: 30px;
    font-size: 10px;
    border-radius: 3px;
    color: #fff;
    background: #e31629;
    text-align: center;
    line-height: 30px;
}
@media screen and (max-width: 720px){
  .free-right{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .free-left {
    display: none;
  }
  .free-right{
    margin: auto;
  }
}
@media only screen and (min-width: 720px) and (max-width: 900px){
  .free-right{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
<style>
.free-left .el-radio__inner {
  display: none;
}
.free-left .el-radio {
  margin-right: 0;
}
</style>